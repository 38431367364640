const SoccerShoesKids = () => {
  return (
    <>
      <h4>CALÇADOS DE FUTEBOL CRIANÇA</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Comprimento do pé: (em cm)</th>
              <td>17.6 - 18.2</td>
              <td>18.3 - 18.8</td>
              <td>18.9 - 19.5</td>
              <td>19.6 - 20.2</td>
              <td>20.3 - 20.8</td>
              <td>20.9 - 21.5</td>
              <td>21.6 - 22.2 </td>
              <td>22.3 - 22.8</td>
              <td>22.9 - 23.5</td>
              <td>23.6 - 24.2</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU</th>
              <td>29</td>
              <td>30</td>
              <td>31</td>
              <td>32</td>
              <td>33</td>
              <td>34</td>
              <td>35</td>
              <td>36</td>
              <td>37</td>
              <td>38</td>
            </tr>
            <tr>
              <th>Tamanhos USA</th>
              <td>11</td>
              <td>12</td>
              <td>12.5</td>
              <td>13.5</td>
              <td>1.5</td>
              <td>2</td>
              <td>3</td>
              <td>3.5</td>
              <td>4.5</td>
              <td>5.5</td>
            </tr>
            <tr>
              <th>Tamanhos Brasil</th>
              <td>27</td>
              <td>28</td>
              <td>29</td>
              <td>30</td>
              <td>31</td>
              <td>32</td>
              <td>33</td>
              <td>34</td>
              <td>35</td>
              <td>36</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>½ tamanhos disponíveis para alguns produtos:</p>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Comprimento do pé: (em cm)</th>
              <td>18.9 - 19.1 </td>
              <td>19.9 - 20.1</td>
              <td>20.4 - 20.6</td>
              <td>21.4 - 21.6</td>
              <td>22.9 - 23.1</td>
              <td>23.4 - 23.6</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU</th>
              <td>29½</td>
              <td>31½</td>
              <td>32½</td>
              <td>34½</td>
              <td>36½</td>
              <td>37½</td>
            </tr>
            <tr>
              <th>Tamanhos USA</th>
              <td>11.5</td>
              <td>13</td>
              <td>1</td>
              <td>2.5</td>
              <td>4</td>
              <td>5</td>
            </tr>
            <tr>
              <th>Tamanhos Brasil</th>
              <td>27½</td>
              <td>29½</td>
              <td>31½</td>
              <td>33½</td>
              <td>34½</td>
              <td>35½</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SoccerShoesKids
