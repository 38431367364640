const TypesOfBicycles = () => {
  return (
    <>
      <h4>BICICLETA PARA ESTRADA SÉRIE ESPORTE</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Sua altura : (em cm)</th>
              <td>142</td>
              <td>150</td>
              <td>159</td>
              <td>167</td>
              <td>175</td>
              <td>184</td>
              <td>192</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos</th>
              <td>45</td>
              <td>48</td>
              <td>51</td>
              <td>54</td>
              <td>57</td>
              <td>60</td>
              <td>63</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>BICICLETA PARA ESTRADA SÉRIE COMPETIÇÃO</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Sua altura : (em cm)</th>
              <td>159</td>
              <td>167</td>
              <td>174</td>
              <td>178</td>
              <td>183</td>
              <td>190</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos</th>
              <td>51</td>
              <td>53</td>
              <td>55</td>
              <td>57</td>
              <td>59</td>
              <td>61</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>CAPACETE DE BICICLETA ADULTO E CRIANÇA</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da cabeça : (em cm)</th>
              <td>51.1</td>
              <td>52</td>
              <td>52.9</td>
              <td>53.8</td>
              <td>54.7</td>
              <td>55.6</td>
              <td>56.5</td>
              <td>57.4</td>
              <td>58.3</td>
              <td>59.2</td>
              <td>60.1</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos</th>
              <td>T0</td>
              <td>T1</td>
              <td>T2</td>
              <td>T3</td>
              <td>T4</td>
              <td>T5</td>
              <td>T6</td>
              <td>T7</td>
              <td>T8</td>
              <td>T9</td>
              <td>T10</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>CAPACETE DE BICICLETA BEBÊ</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da cabeça : (em cm)</th>
              <td>41.1</td>
              <td>43.6</td>
              <td>44.9</td>
              <td>45.7</td>
              <td>47.4</td>
              <td>48.6</td>
              <td>49.7</td>
              <td>50.6</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos</th>
              <td>3 Meses</td>
              <td>6 Meses</td>
              <td>9 Meses</td>
              <td>12 Meses</td>
              <td>18 Meses</td>
              <td>2 Anos</td>
              <td>3 Anos</td>
              <td>4 Anos</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TypesOfBicycles
