const DuckFeetMen = () => {
  return (
    <>
      <h4>PÉS DE PATO / BOTAS DE MERGULHO HOMEM</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Comprimento do pé: (em cm)</th>
              <td>24.3 - 24.9</td>
              <td>25 - 25.6</td>
              <td>25.7 - 26.2</td>
              <td>26.3 - 26.8</td>
              <td>26.9 - 27.5</td>
              <td>27.6 - 28.2</td>
              <td>28.3 - 28.8</td>
              <td>28.9 - 29.5</td>
              <td>29.6 - 30.2</td>
              <td>30.3 - 30.8</td>
              <td>30.9 - 31.5 </td>
              <td>31.6 - 32.2</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU</th>
              <td>39</td>
              <td>40</td>
              <td>41</td>
              <td>42</td>
              <td>43</td>
              <td>44</td>
              <td>45</td>
              <td>46</td>
              <td>47</td>
              <td>48</td>
              <td>49</td>
              <td>50</td>
            </tr>
            <tr>
              <th>Tamanhos USA</th>
              <td>6</td>
              <td>7</td>
              <td>7.5</td>
              <td>8.5</td>
              <td>9</td>
              <td>10</td>
              <td>11</td>
              <td>11.5</td>
              <td>12</td>
              <td>13</td>
              <td>13.5</td>
              <td>14</td>
            </tr>
            <tr>
              <th>Tamanhos Brasil</th>
              <td>37</td>
              <td>38</td>
              <td>39</td>
              <td>40</td>
              <td>41</td>
              <td>42</td>
              <td>43</td>
              <td>44</td>
              <td>45</td>
              <td>46</td>
              <td>47</td>
              <td>49</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>½ tamanhos disponíveis para alguns produtos:</p>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Comprimento do pé: (em cm)</th>
              <td>24.9 - 25.1</td>
              <td>26.4 - 26.6</td>
              <td>27.4 - 27.6</td>
              <td>28.4 - 28.6</td>
              <td>30.4 - 30.6</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU</th>
              <td>39 ½</td>
              <td>41 ½</td>
              <td>43 ½</td>
              <td>44 ½</td>
              <td>47 ½</td>
            </tr>
            <tr>
              <th>Tamanhos USA</th>
              <td>6.5</td>
              <td>8</td>
              <td>9.5</td>
              <td>10.5</td>
              <td>12.5</td>
            </tr>
            <tr>
              <th>Tamanhos Brasil</th>
              <td>37 ½</td>
              <td>39 ½</td>
              <td>41 ½</td>
              <td>42 ½</td>
              <td>45 ½</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DuckFeetMen
