import { Link } from 'gatsby'

import DuckFeetMen from './DuckFeetMen'
import DuckFeetWomen from './DuckFeetWomen'
import DuckFeetKids from './DuckFeetKids'
import GlovesDiving from './GlovesDiving'

const SizeGuideDiving = () => {
  return (
    <>
      <section className="tamanho-esporte-mergulho guia-section-block">
        <h3>GUIA DE TAMANHOS MERGULHO</h3>
        <h4>COMBINAÇÃO / SHORTY</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Sua altura : (em cm)</th>
                <td>166 - 170</td>
                <td>171 - 175</td>
                <td>176 - 180</td>
                <td>181 - 185</td>
                <td>186 - 190</td>
                <td>191 - 195</td>
                <td>196 - 201</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Medida do peito: (em cm)</th>
                <td>84</td>
                <td>92</td>
                <td>100</td>
                <td>108</td>
                <td>116</td>
                <td>124</td>
                <td>132</td>
              </tr>
              <tr>
                <th>Medida da cintura: (em cm)</th>
                <td>72</td>
                <td>78</td>
                <td>84</td>
                <td>90</td>
                <td>96</td>
                <td>102</td>
                <td>108</td>
              </tr>
              <tr>
                <th>Medição hanche: (em cm)</th>
                <td>84</td>
                <td>90</td>
                <td>96</td>
                <td>102</td>
                <td>108</td>
                <td>114</td>
                <td>120</td>
              </tr>
              <tr>
                <th>Tamanhos</th>
                <td>36</td>
                <td>38</td>
                <td>40</td>
                <td>42 / 44</td>
                <td>46 / 48</td>
                <td>50 / 52</td>
                <td>54</td>
              </tr>
              <tr>
                <th>Tamanhos universais</th>
                <td>PP</td>
                <td>P</td>
                <td>M</td>
                <td>G</td>
                <td>GG</td>
                <td>3G</td>
                <td>4G</td>
              </tr>
            </tbody>
          </table>
        </div>
        <DuckFeetMen />
        <DuckFeetWomen />
        <DuckFeetKids />
        <GlovesDiving />
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideDiving
