const TypesOfGloves = () => {
  return (
    <>
      <h4>LUVAS DE BICICLETA HOMEM</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da mão : (em cm)</th>
              <td>20.25-20.85</td>
              <td>20.86-21.5</td>
              <td>21.51-22.15</td>
              <td>22.16-22.8</td>
              <td>22.81-23.45</td>
              <td>23.46-24.1</td>
              <td>24.11-24.75</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos universais</th>
              <td>PP</td>
              <td>P</td>
              <td>M</td>
              <td>G</td>
              <td>GG</td>
              <td>3G</td>
              <td>4G</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>LUVAS DE BICICLETA MULHER</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da mão : (em cm)</th>
              <td>16.25-17.1</td>
              <td>17.11-17.95</td>
              <td>17.96-18.8</td>
              <td>18.81-19.5</td>
              <td>19.51-20.1</td>
              <td>20.11-20.7</td>
              <td>20.71-21.3</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos universais</th>
              <td>3P</td>
              <td>PP</td>
              <td>P</td>
              <td>M</td>
              <td>G</td>
              <td>GG</td>
              <td>3G</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>LUVAS DE BICICLETA CRIANÇA</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da mão : (em cm)</th>
              <td>13.9-14.9</td>
              <td>14.91-15.9</td>
              <td>15.91-16.9</td>
              <td>16.91-17.8</td>
              <td>17.81-18.6</td>
              <td>18.61-19.45</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos universais</th>
              <td>4 Anos</td>
              <td>6 Anos</td>
              <td>8 Anos</td>
              <td>10 Anos</td>
              <td>12 Anos</td>
              <td>14 Anos</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TypesOfGloves
