import { Link } from 'gatsby'

import Soutiens from './Soutiens'

const SizeGuideAquaticSports = () => {
  return (
    <>
      <section className="tamanho-esporte-aquatico guia-section-block">
        <h3>GUIA DE TAMANHOS ESPORTES AQUÁTICOS</h3>
        <h4>Roupa de banho - MULHER</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Cintura : (em cm)</th>
                <td>56-59</td>
                <td>60-63</td>
                <td>64-67</td>
                <td>68-71</td>
                <td>72-75</td>
                <td>76-79</td>
                <td>80-83</td>
                <td>84-89</td>
                <td>90-95</td>
                <td>96-101</td>
                <td>102-107</td>
                <td>108-113</td>
                <td>114-119</td>
                <td>120-125</td>
                <td>126-131</td>
                <td>132-137</td>
              </tr>
              <tr>
                <th>Quadris : (em cm)</th>
                <td>86-89</td>
                <td>90-93</td>
                <td>94-97</td>
                <td>98-101</td>
                <td>102-105</td>
                <td>106-109</td>
                <td>110-113</td>
                <td>114-119</td>
                <td>120-125</td>
                <td>126-131</td>
                <td>132-137</td>
                <td>138-143</td>
                <td>144-149</td>
                <td>150-155</td>
                <td>156-161</td>
                <td>162-167</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos EU - Europa</th>
                <td>34</td>
                <td>36</td>
                <td>38</td>
                <td>40</td>
                <td>42</td>
                <td>44</td>
                <td>46</td>
                <td>48</td>
                <td>50</td>
                <td>52</td>
                <td>54</td>
                <td>56</td>
                <td>58</td>
                <td>60</td>
                <td>62</td>
                <td>64</td>
              </tr>
              <tr>
                <th>Tamanhos</th>
                <td>3P</td>
                <td>PP</td>
                <td>P</td>
                <td>M</td>
                <td>M-G</td>
                <td>G</td>
                <td>G-GG</td>
                <td>GG</td>
                <td>GG-3G</td>
                <td>3G</td>
                <td>3G-4G</td>
                <td>4G</td>
                <td>4G-5G</td>
                <td>5G</td>
                <td>5G-6G</td>
                <td>6G</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Se as duas medidas não coincidem, privilegiar a medida do quadril para
          todas as partes de baixo, exceto os tipos fio dental, para os quais se
          levará em conta a medida da cintura.
        </p>
        <Soutiens />
        <h4>Roupa de banho - HOMEM</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da cintura : (em cm)</th>
                <td>70-73</td>
                <td>74-77</td>
                <td>78-81</td>
                <td>82-85</td>
                <td>86-89</td>
                <td>90-95</td>
                <td>96-100</td>
                <td>101-105</td>
                <td>106-111</td>
                <td>112-117</td>
                <td>118-123</td>
                <td>124-129</td>
                <td>130-135</td>
                <td>136-141</td>
                <td>142-147</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos EU - Europa</th>
                <td>36</td>
                <td>38</td>
                <td>40</td>
                <td>42</td>
                <td>44</td>
                <td>46</td>
                <td>48</td>
                <td>50</td>
                <td>52</td>
                <td>54</td>
                <td>56</td>
                <td>58</td>
                <td>60</td>
                <td>62</td>
                <td>64</td>
              </tr>
              <tr>
                <th>Tamanhos</th>
                <td>PP</td>
                <td>P</td>
                <td>M</td>
                <td>M - G</td>
                <td>G</td>
                <td>G - GG</td>
                <td>GG</td>
                <td>GG - 3G</td>
                <td>3G</td>
                <td>3G - 4G</td>
                <td>4G</td>
                <td>4G - 5G</td>
                <td>5G</td>
                <td>5G - 6G</td>
                <td>6G</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideAquaticSports
