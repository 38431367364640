const WomenUnderwear = () => {
  return (
    <>
      <h4>PARTE DE BAIXO MULHER: Shorts, calças, roupa de baixo...</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da cintura : (em cm)</th>
              <td>58-62</td>
              <td>63-65</td>
              <td>66-69</td>
              <td>70-73</td>
              <td>74-84</td>
              <td>85-96</td>
              <td>97-108</td>
              <td>109-120</td>
              <td>121-132</td>
              <td>133-145</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU -Europa</th>
              <td>2XS</td>
              <td>XS</td>
              <td>S</td>
              <td>M</td>
              <td>L</td>
              <td>XL</td>
              <td>2XL</td>
              <td>3XL</td>
              <td>4XL</td>
              <td>5XL</td>
            </tr>
            <tr>
              <th>Tamanhos</th>
              <td>3P</td>
              <td>PP</td>
              <td>P</td>
              <td>M</td>
              <td>G</td>
              <td>GG</td>
              <td>3G</td>
              <td>4G</td>
              <td>5G</td>
              <td>6G</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>PARTE DE BAIXO MULHER: Roupa de banho...</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Cintura : (em cm)</th>
              <td>56-59</td>
              <td>60-63</td>
              <td>64-67</td>
              <td>68-71</td>
              <td>72-75</td>
              <td>76-79</td>
              <td>80-83</td>
              <td>84-89</td>
              <td>90-95</td>
              <td>96-101</td>
              <td>102-107</td>
              <td>108-113</td>
              <td>114-119</td>
              <td>120-125</td>
              <td>126-131</td>
              <td>132-137</td>
            </tr>
            <tr>
              <th>Quadris : (em cm)</th>
              <td>86-89</td>
              <td>90-93</td>
              <td>94-97</td>
              <td>98-101</td>
              <td>102-105</td>
              <td>106-109</td>
              <td>110-113</td>
              <td>114-119</td>
              <td>120-125</td>
              <td>126-131</td>
              <td>132-137</td>
              <td>138-143</td>
              <td>144-149</td>
              <td>150-155</td>
              <td>156-161</td>
              <td>162-167</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU - Europa</th>
              <td>34</td>
              <td>36</td>
              <td>38</td>
              <td>40</td>
              <td>42</td>
              <td>44</td>
              <td>46</td>
              <td>48</td>
              <td>50</td>
              <td>52</td>
              <td>54</td>
              <td>56</td>
              <td>58</td>
              <td>60</td>
              <td>62</td>
              <td>64</td>
            </tr>
            <tr>
              <th>Tamanhos</th>
              <td>3P</td>
              <td>PP</td>
              <td>P</td>
              <td>M</td>
              <td>M-G</td>
              <td>G</td>
              <td>G-GG</td>
              <td>GG</td>
              <td>GG-3G</td>
              <td>3G</td>
              <td>3G-4G</td>
              <td>4G</td>
              <td>4G-5G</td>
              <td>5G</td>
              <td>5G-6G</td>
              <td>6G</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>LUVAS MULHER: VTT, bicicleta, mergulho...</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da mão : (em cm)</th>
              <td>16.25-17.1</td>
              <td>17.11-17.95</td>
              <td>17.96-18.8 </td>
              <td>18.81-19.5</td>
              <td>19.51-20.1</td>
              <td>20.11-20.7</td>
              <td>20.71-21.3</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos universais</th>
              <td>3P</td>
              <td>PP</td>
              <td>P</td>
              <td>M</td>
              <td>G</td>
              <td>GG</td>
              <td>3G</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default WomenUnderwear
