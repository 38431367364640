import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'

import ChildGlove from './ChildGlove'
import GirlsUpsideUnderwear from './GirlsUpsideUnderwear'

const SizeGuideKids = () => {
  return (
    <>
      <section className="tamanho-vestuario-crianca guia-section-block">
        <h3>GUIA DE TAMANHOS TÊXTEIS CRIANÇA</h3>
        <div className="guia-section-description">
          <div className="image-container">
            <Image
              src="https://decathlonproqa.vtexassets.com/arquivos/tamanhos-crianca.png"
              alt="kids-size"
              loading="lazy"
              width={220}
              height={404}
            />
          </div>
          <div className="description-container">
            <span>Para tirar suas medidas:</span>
            <ul>
              <li>
                Altura:Coloque-se contra uma parede, descalço, calcanhares
                colados na parede.
                <br />
                Fique bem reto, depois coloque um livro no alto de sua cabeça.
                <br />
                Bloqueie este livro, faça uma marca na parede e meça a distância
                entre a marca e o chão.
              </li>
              <li>
                Medida da cabeça:Coloque uma fita métrica acima das
                sobrancelhas. Certifique-se de que está paralela ao chão quando
                sua cabeça está reta.
              </li>
              <li>
                Medida da mão:Coloque uma fita métrica na dobra do polegar. Meça
                a palma com a fita métrica.
              </li>
              <li>
                Comprimento do pé:Coloque-se contra uma parede, descalço,
                calcanhares colados na parede.
                <br />
                Coloque um livro ou um objeto pesado contra seu dedão. Meça a
                distância entre o livro ou o objeto e a parede.
              </li>
            </ul>
          </div>
        </div>
        <h4>
          PARTE DE CIMA / PARTE DE BAIXO MENINO: Camisetas, agasalho com capuz,
          casacos, jaquetas com capuz...
        </h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Altura: (em cm)</th>
                <td>56-63</td>
                <td>64-70</td>
                <td>71-76</td>
                <td>77-82</td>
                <td>83-89</td>
                <td>90-97</td>
                <td>98-104</td>
                <td>105-114</td>
                <td>115-124</td>
                <td>125-132</td>
                <td>133-142</td>
                <td>143-152</td>
                <td>153-162</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos universais</th>
                <td>3 meses</td>
                <td>6 à 9 meses</td>
                <td>9 à 12 meses</td>
                <td>18 meses</td>
                <td>2 anos</td>
                <td>3 anos</td>
                <td>4 anos</td>
                <td>5 anos</td>
                <td>6 anos</td>
                <td>8 anos</td>
                <td>10 anos</td>
                <td>12 anos</td>
                <td>14 anos</td>
              </tr>
            </tbody>
          </table>
        </div>
        <GirlsUpsideUnderwear />
        <ChildGlove />
        <h4>ACESSÓRIOS DE CABEÇA BEBÊ : Bonés, gorros, capacetes...</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da cabeça: (em cm)</th>
                <td>41.1</td>
                <td>43.6</td>
                <td>44.9</td>
                <td>45.7</td>
                <td>47.4</td>
                <td>48.6</td>
                <td>49.7</td>
                <td>50.6</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos universais</th>
                <td>3 meses</td>
                <td>6 meses</td>
                <td>9 meses</td>
                <td>12 meses</td>
                <td>18 meses</td>
                <td>2 anos</td>
                <td>3 anos</td>
                <td>4 anos</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>ACESSÓRIOS DE CABEÇA CRIANÇA : Bonés, gorros, capacetes...</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da cabeça: (em cm)</th>
                <td>51.1</td>
                <td>52</td>
                <td>52.9</td>
                <td>53.8</td>
                <td>54.7</td>
                <td>55.6</td>
                <td>56.5</td>
                <td>57.4</td>
                <td>58.3</td>
                <td>59.2</td>
                <td>60.1</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos universais</th>
                <td>T0</td>
                <td>T1</td>
                <td>T2</td>
                <td>T3</td>
                <td>T4</td>
                <td>T5</td>
                <td>T6</td>
                <td>T7</td>
                <td>T8</td>
                <td>T9</td>
                <td>T10</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideKids
