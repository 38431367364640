import { Link } from 'gatsby'

const SizeGuideFitness = () => {
  return (
    <>
      <section className="tamanho-esporte-ginastica guia-section-block">
        <h3>GUIA DE TAMANHOS FITNESS, DANÇA, COMBATE</h3>
        <h4>LUVAS DE BOXE HOMEM</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Seu peso : (em Kg)</th>
                <td>45 - 60</td>
                <td>60 - 80</td>
                <td>80 - 90</td>
                <td>90 - 100</td>
                <td>+ 100</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>8 oz</td>
                <td>10 oz</td>
                <td>12 oz</td>
                <td>14 oz</td>
                <td>16 oz</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>LUVAS DE BOXE MULHER</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Seu peso : (em Kg)</th>
                <td>45 - 60</td>
                <td>60 - 80</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>8 oz</td>
                <td>10 oz</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>LUVAS DE BOXE CRIANÇA</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Seu peso : (em Kg)</th>
                <td>20 - 25</td>
                <td>25 - 45</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>6 oz</td>
                <td>8 oz</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>LUVAS DE FRAXPE HOMEM</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da mão : (em cm)</th>
                <td>20.86-21.5</td>
                <td>21.51-22.15</td>
                <td>22.16-22.8</td>
                <td>22.81-23.45</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos universais</th>
                <td>P</td>
                <td>M</td>
                <td>G</td>
                <td>GG</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>LUVAS DE FRAXPE MULHER</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da mão : (em cm)</th>
                <td>17.96-18.8</td>
                <td>18.81-19.5</td>
                <td>19.51-20.1</td>
                <td>20.11-20.7</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos universais</th>
                <td>P</td>
                <td>M</td>
                <td>G</td>
                <td>GG</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideFitness
