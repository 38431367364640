import { Link } from 'gatsby'

import HorsemanshipItems from './HorsemanshipItems'

const SizeGuideHorsemanship = () => {
  return (
    <>
      <section className="tamanho-esporte-equitacao guia-section-block">
        <h3>GUIA DE TAMANHOS EQUITAÇÃO</h3>
        <h4>BOMBA (CAPACETE DE EQUITAÇÃO)</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da cabeça : (em cm)</th>
                <td>48 - 51</td>
                <td>52 - 55</td>
                <td>56 - 60</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>P</td>
                <td>M</td>
                <td>G</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>MINI-CHAPS ADULTO</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da panturrilha: (em cm)</th>
                <td>30.5 - 31.5</td>
                <td>31 - 33.9</td>
                <td>31 - 33.9</td>
                <td>34 - 36.9</td>
                <td>37 - 39.9</td>
                <td>31 - 33.9</td>
                <td>34 - 36.9</td>
                <td>37 - 39.9</td>
                <td>40 - 43</td>
              </tr>
              <tr>
                <th>Altura do cano: (em cm)</th>
                <td>25.5 - 30.4</td>
                <td>30.5 - 33.9</td>
                <td>34 - 37.9</td>
                <td>34 - 37.9</td>
                <td>34 - 37.9</td>
                <td>38 - 42</td>
                <td>38 - 42</td>
                <td>38 - 42</td>
                <td>38 - 42</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>P-28</td>
                <td>P-32</td>
                <td>P-36</td>
                <td>M-36</td>
                <td>G-36</td>
                <td>G-39</td>
                <td>M-39</td>
                <td>G-39</td>
                <td>XG-39</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>MINI-CHAPS CRIANÇA</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da panturrilha: (em cm)</th>
                <td>26 - 29</td>
                <td>27.5 - 30.5</td>
                <td>27.5 - 30.5</td>
                <td>28 - 31</td>
              </tr>
              <tr>
                <th>Altura do cano: (em cm)</th>
                <td>17 - 20.9</td>
                <td>21 - 25.4</td>
                <td>25.5 - 30.4</td>
                <td>30.5 - 34</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>4 anos</td>
                <td>6 anos</td>
                <td>8 anos</td>
                <td>10 anos</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>FREIO</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Montaria</th>
                <td>Shetland (SHT)</td>
                <td>Pônei (PS)</td>
                <td>Cavalo pequeno</td>
                <td>Cavalo (FS)</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>85 - 95 cm</td>
                <td>90 - 105 cm</td>
                <td>95 - 115 cm</td>
                <td>100 - 125 cm</td>
              </tr>
            </tbody>
          </table>
        </div>
        <HorsemanshipItems />
        <h4>PROTEÇÕES</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Montaria : </th>
                <td>Shetland</td>
                <td>Pônei</td>
                <td>Cavalo Pequeno</td>
                <td>Cavalo</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>SHT</td>
                <td>PS</td>
                <td>CS</td>
                <td>FS</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideHorsemanship
