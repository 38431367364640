import { Link } from 'gatsby'

import TypesOfBicycles from './TypesOfbicycles'
import TypesOfGloves from './TypesOfGloves'

const SizeGuideBicycle = () => {
  return (
    <>
      <section className="tamanho-esporte-bicicleta guia-section-block">
        <h3>GUIA DE TAMANHOS BICICLETA</h3>
        <h4>BICICLETA ADULTO</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Modelo bicicleta</th>
                <td>Tamanho M 26 polegares</td>
                <td>Tamanho L 28 polegares</td>
                <td>Tamanho XL 28 polegares</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Gama cidade</th>
                <td>155cm à 173cm</td>
                <td>173cm à 185cm</td>
                <td>185cm à 195cm</td>
              </tr>
              <tr>
                <th>Gama polivalente</th>
                <td>155cm à 173cm</td>
                <td>173cm à 185cm</td>
                <td>não vendido</td>
              </tr>
              <tr>
                <th>Gamme trekking</th>
                <td>155cm à 173cm</td>
                <td>173cm à 185cm</td>
                <td>185cm à 195cm</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>BICICLETA CRIANÇA</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Sua altura : (em cm)</th>
                <td>75 - 90</td>
                <td>90 - 105</td>
                <td>105 - 120</td>
                <td>120 - 135</td>
                <td>135 - 155</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos da bicicleta</th>
                <td>12&quot;</td>
                <td>14&quot;</td>
                <td>16&quot;</td>
                <td>20&quot;</td>
                <td>24&quot;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>VTT</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Sua altura : (em cm)</th>
                <td>135 - 150</td>
                <td>150 - 165</td>
                <td>165 - 173</td>
                <td>173 - 176</td>
                <td>176 - 180</td>
                <td>180 - 184</td>
                <td>184 - 195</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos da bicicleta</th>
                <td>PP</td>
                <td>P</td>
                <td>M</td>
                <td>M / G</td>
                <td>G</td>
                <td>G / XG</td>
                <td>GG</td>
              </tr>
            </tbody>
          </table>
        </div>
        <TypesOfBicycles />
        <TypesOfGloves />
        <h4>PNEU DE BICICLETA</h4>
        <p>
          Para escolher corretamente o tamanho, veja as dimensões indicadas no
          pneu de sua bicicleta.
          <br />
          O diâmetro padrão de VTT [Bicicleta para qualquer terreno] é de 26
          polegares.
          <br />
          Por exemplo, 26 x 2,00 é uma dimensão geralmente encontrada nos pneus
          VTT. <br />
          O primeiro número traduz o diâmetro do pneu, e o segundo seu
          comprimento. É imperativo que se conserve o mesmo diâmentro quando
          você troca de pneu.
          <br />
          Por outro lado, você pode escolher um modelo mais ou menos estreito
          que seu pneu original: mais o segundo número é alto, mais o pneu é
          largo.
          <br />
          Um pneu estrito lhe dará um melhor rendimento, enquanto que um pneu
          mais largo privilegiará o conforto.
        </p>
        <p>
          No entanto, não se esqueça de verificar a compatibilidade do seu pneu
          com câmara de ar: asmedidas indicadas no pneu devem corresponder com
          as descritas na câmara de ar.
          <br />
          Se o tamanho da câmara de ar é de 26 x 1,75 / 2,1, isto significa que
          você pode utilizar um pneu de diâmetro 26 cujo comprimento será
          compreendido entre 1,75 e 2,1 inclusive.
          <br />
          Por exemplo, uma câmara de ar de 26 x 1,75 / 2,1 será compatível com
          um pneu 26 x 2,00.
        </p>
        <h4>CÂMARA DE AR DE BICICLETA</h4>
        <p>
          O primeiro passo é verificar o tipo de válvula procurado, ou seja:
        </p>
        <p>
          - Valve Presta (pequena válvula) é encontrado na maioria das
          bicicletas para estrada. Alguns modelos estão equipados com válvulas
          longas para compatibilidade perfeita com juntas perfiladas.
          <br />- Valve Schrader (grande válvula): mais dedicada às VTT.
        </p>
        <p>
          Em seguida, selecione o tamanho de câmara de ar adaptado ao pneu:
          <br />
          O diâmetro padrão na bicicleta para estrada é de 700 (também chamado
          de 28 polegares ).
          <br />
          Algumas rodas menores podem ser ede (= 26 polegares ).
          <br />
          Veja as dimensões indicadas na câmara de ar a ser substituída ou no
          pneu. O primeiro número traduz o diâmetro e o segundo o comprimento.
          <br />É imperativo de conservar o mesmo diâmetro quando você troca a
          câmera de ar.
        </p>
        <p>
          Uma câmara de ar 700 x 18 / 25 será compatível com todos os pneus de
          diâmetro 700 cujo comprimento estará compreendido entre 18 e 25
          inclusive. Por exemplo, uma câmara de ar 700 x 18 / 25 poderá ser
          utilizada com um pneu 700 x 23.{' '}
        </p>
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideBicycle
