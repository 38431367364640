import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'

import HeadAccessoriesMen from './HeadAccessoriesMen'

const SizeGuideMen = () => {
  return (
    <>
      <section className="tamanho-vestuario-homem guia-section-block">
        <h3>GUIA DE TAMANHOS TÊXTEIS HOMEM</h3>
        <div className="guia-section-description">
          <div className="image-container">
            <Image
              src="https://decathlonproqa.vtexassets.com/arquivos/tamanhos-homem.png"
              alt="men-size"
              loading="lazy"
              width={220}
              height={404}
            />
          </div>
          <div className="description-container">
            <span>Para tirar suas medidas:</span>
            <ul>
              <li>
                Medida da cabeça: Coloque uma fita métrica acima das
                sobrancelhas. Certifique-se de que está paralela ao chão quando
                sua cabeça está à direita
              </li>
              <li>
                Medida do peito: Coloque uma fita métrica no lugar mais próximo
                do peito. Certifique-se de que está paralela ao chão.
                <br />
                Levante a cabeça, aperte a fita métrica respirando normalmente.
              </li>
              <li>
                Medida da cintura: Coloque uma fita métrica abaixo do umbigo no
                lugar mais fino de sua cintura.
                <br />
                Levante a cabeça, aperte a fita métrica respirando normalmente.
              </li>
              <li>
                Medida da mão: Coloque uma fita métrica na dobra do polegar.
                Meça a palma com a fita métrica.
              </li>
              <li>
                Comprimento do pé: Coloque-se contra uma parede, descalço,
                calcanhares colados na parede.
                <br />
                Coloque um livro ou um objeto pesado contra seu dedão. Meça a
                distância entre o livro ou o objeto e a parede.
              </li>
            </ul>
          </div>
        </div>
        <h4>
          PARTE DE CIMA HOMEM: Camisetas, regatas, camisas, jaquetas com capuz,
          agasalho com capuz, casacos...
        </h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida do peito: (em cm)</th>
                <td>84-87</td>
                <td>88-91</td>
                <td>92-95</td>
                <td>96-103</td>
                <td>104-113</td>
                <td>114-123</td>
                <td>124-135</td>
                <td>136-146</td>
                <td>147-159</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos EU -Europa</th>
                <td>XS</td>
                <td>S</td>
                <td>M</td>
                <td>L</td>
                <td>XL</td>
                <td>2XL</td>
                <td>3XL</td>
                <td>4XL</td>
                <td>5XL</td>
              </tr>
              <tr>
                <th>Tamanhos</th>
                <td>PP</td>
                <td>P</td>
                <td>M</td>
                <td>G</td>
                <td>GG</td>
                <td>3G</td>
                <td>4G</td>
                <td>5G</td>
                <td>6G</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>
          PARTE DE BAIXO HOMEM: Shorts, calças, roupa de baixo, roupa de
          banho...
        </h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da cintura : (em cm)</th>
                <td>70-73</td>
                <td>74-77</td>
                <td>78-81</td>
                <td>82-89</td>
                <td>90-100</td>
                <td>101-111</td>
                <td>112-123</td>
                <td>124-135</td>
                <td>136-147</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos EU -Europa</th>
                <td>XS</td>
                <td>S</td>
                <td>M</td>
                <td>L</td>
                <td>XL</td>
                <td>2XL</td>
                <td>3XL</td>
                <td>4XL</td>
                <td>5XL</td>
              </tr>
              <tr>
                <th>Tamanhos</th>
                <td>PP</td>
                <td>P</td>
                <td>M</td>
                <td>G</td>
                <td>GG</td>
                <td>3G</td>
                <td>4G</td>
                <td>5G</td>
                <td>6G</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>LUVAS HOMEM: VTT, bicicleta, mergulho...</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da mão : (em cm)</th>
                <td>20.25-20.85</td>
                <td>20.86-21.5</td>
                <td>21.51-22.15</td>
                <td>22.16-22.8</td>
                <td>22.81-23.45 </td>
                <td>23.46-24.1</td>
                <td>24.11-24.75</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos universais</th>
                <td>PP</td>
                <td>P</td>
                <td>M</td>
                <td>G</td>
                <td>GG</td>
                <td>3G</td>
                <td>4G</td>
              </tr>
            </tbody>
          </table>
        </div>
        <HeadAccessoriesMen />
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideMen
