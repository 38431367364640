import { Link } from 'gatsby'

import Button from '../../ui/Button'
import SizeGuideMen from './SizeGuideMen/SizeGuideMen'
import SizeGuideWomen from './SizeGuideWomen/SizeGuideWomen'
import SizeGuideKids from './SizeGuideKids/SizeGuideKids'
import SizeGuideShoes from './SizeGuideShoes/SizeGuideShoes'
import SizeGuideBicycle from './SizeGuideBicycle/SizeGuideBicycle'
import SizeGuideRoller from './SizeGuideRoller/SizeGuideRoller'
import SizeGuideDiving from './SizeGuideDiving/SizeGuideDiving'
import SizeGuidePrecisionSports from './SizeGuidePrecisionSports/SizeGuidePrecisionSports'
import SizeGuideAquaticSports from './SizeGuideAquaticSports/SizeGuideAquaticSports'
import SizeGuideColetiveSports from './SizeGuideColetiveSports/SizeGuideColetiveSports'
import SizeGuideFitness from './SizeGuideFitness/SizeGuideFitness'
import SizeGuideHorsemanship from './SizeGuideHorsemanship/SizeGuideHorsemanship'
import './styles.scss'

const SizeGuide = () => {
  return (
    <>
      <main className="main w-full" id="main">
        <div className="guia-de-tamanho">
          <div className="wrapper">
            <div className="guia-title">
              <h2>
                Guia de <span>tamanho</span>
              </h2>
            </div>
            <div className="guia-contents">
              <div className="tamanho-geral">
                <div className="tamanho-vestuario">
                  <span className="tamanho-geral-title">
                    Guia de tamanho vestuários
                  </span>
                  <ul>
                    <li className="tamanho-vestuario__homem">
                      <Button onClick={() => window.scrollTo(0, 450)}>
                        <Link className="link_categories" to="#vestuario-homem">
                          Homem
                        </Link>
                      </Button>
                    </li>

                    <li className="tamanho-vestuario__mulher">
                      <Button onClick={() => window.scrollTo(0, 1400)}>
                        <Link
                          className="link_categories"
                          to="#vestuario-mulher"
                        >
                          Mulher
                        </Link>
                      </Button>
                    </li>

                    <li className="tamanho-vestuario__crianca">
                      <Button onClick={() => window.scrollTo(0, 2900)}>
                        <Link
                          className="link_categories"
                          to="#vestuario-crianca"
                        >
                          Criança
                        </Link>
                      </Button>
                    </li>
                  </ul>
                </div>
                <div className="tamanho-calcado">
                  <span className="tamanho-geral-title">
                    Guia de tamanho calçados
                  </span>
                  <ul>
                    <li className="tamanho-calcado__homem">
                      <Button onClick={() => window.scrollTo(0, 4200)}>
                        <Link className="link_categories" to="#calcado-homem">
                          Homem
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-calcado__mulher">
                      <Button onClick={() => window.scrollTo(0, 4500)}>
                        <Link className="link_categories" to="#calcado-mulher">
                          Mulher
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-calcado__crianca">
                      <Button onClick={() => window.scrollTo(0, 4800)}>
                        <Link className="link_categories" to="#calcado-crianca">
                          Criança
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-calcado__bebe">
                      <Button onClick={() => window.scrollTo(0, 5100)}>
                        <Link className="link_categories" to="#calcado-bebe">
                          Bebê
                        </Link>
                      </Button>
                    </li>
                  </ul>
                </div>
                <div className="tamanho-esporte">
                  <span className="tamanho-geral-title">
                    Guia de tamanho por esportes
                  </span>
                  <ul>
                    <li className="tamanho-esporte__bicicleta">
                      <Button onClick={() => window.scrollTo(0, 5500)}>
                        <Link
                          className="link_categories"
                          to="#esporte-bicicleta"
                        >
                          Bicicleta
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-esporte__patins">
                      <Button onClick={() => window.scrollTo(0, 7300)}>
                        <Link className="link_categories" to="#esporte-patins">
                          Patins, skate, patinete
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-esporte__mergulho">
                      <Button onClick={() => window.scrollTo(0, 8400)}>
                        <Link
                          className="link_categories"
                          to="#esporte-mergulho"
                        >
                          Mergulho
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-esporte__precisao">
                      <Button onClick={() => window.scrollTo(0, 10000)}>
                        <Link
                          className="link_categories"
                          to="#esporte-precisao"
                        >
                          Esportes de precisão
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-esporte__equipe">
                      <Button onClick={() => window.scrollTo(0, 11200)}>
                        <Link className="link_categories" to="#esporte-equipe">
                          Equipe esportiva
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-esporte__ginastica">
                      <Button onClick={() => window.scrollTo(0, 12200)}>
                        <Link
                          className="link_categories"
                          to="#esporte-ginastica"
                        >
                          Ginástica, dança, luta
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-esporte__equitacao">
                      <Button onClick={() => window.scrollTo(0, 12900)}>
                        <Link
                          className="link_categories"
                          to="#esporte-equitacao"
                        >
                          Equitação
                        </Link>
                      </Button>
                    </li>
                    <li className="tamanho-esporte__aquatico">
                      <Button onClick={() => window.scrollTo(0, 10200)}>
                        <Link
                          className="link_categories"
                          to="#esporte-aquatico"
                        >
                          Esportes aquáticos
                        </Link>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <SizeGuideMen />
              <SizeGuideWomen />
              <SizeGuideKids />
              <SizeGuideShoes />
              <SizeGuideBicycle />
              <SizeGuideRoller />
              <SizeGuideDiving />
              <SizeGuidePrecisionSports />
              <SizeGuideAquaticSports />
              <SizeGuideColetiveSports />
              <SizeGuideFitness />
              <SizeGuideHorsemanship />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default SizeGuide
