const HorsemanshipItems = () => {
  return (
    <>
      <h4>CABRESTO</h4>
      <div className="guia-tabela">
        <table className="tabela-colorida">
          <thead>
            <tr>
              <th colSpan={7}>Medida do pescoço : (em cm)</th>
            </tr>
            <tr>
              <th>Altura : (em cm)</th>
              <td>- 65</td>
              <td>65 / 75</td>
              <td>75 / 85</td>
              <td>85 / 95</td>
              <td>95 / 105</td>
              <td>105 / 115</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>- 100 cm</th>
              <td className="td-amarelo" />
              <td className="td-amarelo" />
              <td className="td-amarelo" />
              <td className="td-rosa" />
              <td className="td-verde" />
              <td className="td-verde" />
            </tr>
            <tr>
              <th>110 cm</th>
              <td className="td-amarelo" />
              <td className="td-amarelo" />
              <td className="td-amarelo" />
              <td className="td-rosa" />
              <td className="td-verde" />
              <td className="td-verde" />
            </tr>
            <tr>
              <th>120 cm</th>
              <td className="td-amarelo" />
              <td className="td-amarelo" />
              <td className="td-rosa" />
              <td className="td-rosa" />
              <td className="td-verde" />
              <td className="td-verde" />
            </tr>
            <tr>
              <th>130 cm</th>
              <td className="td-amarelo" />
              <td className="td-amarelo">T1</td>
              <td className="td-rosa" />
              <td className="td-rosa" />
              <td className="td-verde" />
              <td className="td-verde" />
            </tr>
            <tr>
              <th>140 cm</th>
              <td className="td-amarelo" />
              <td className="td-amarelo" />
              <td className="td-rosa" />
              <td className="td-rosa">T2</td>
              <td className="td-verde" />
              <td className="td-verde" />
            </tr>
            <tr>
              <th>150 cm</th>
              <td className="td-amarelo" />
              <td className="td-amarelo" />
              <td className="td-rosa" />
              <td className="td-rosa" />
              <td className="td-verde">T3</td>
              <td className="td-verde" />
            </tr>
            <tr>
              <th>160 cm</th>
              <td className="td-amarelo" />
              <td className="td-amarelo" />
              <td className="td-rosa" />
              <td className="td-verde" />
              <td className="td-verde" />
              <td className="td-verde" />
            </tr>
            <tr>
              <th>160 cm</th>
              <td className="td-amarelo" />
              <td className="td-rosa" />
              <td className="td-rosa" />
              <td className="td-verde" />
              <td className="td-verde" />
              <td className="td-verde" />
            </tr>
          </tbody>
        </table>
      </div>
      <h4>COBERTURA</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Montaria :</th>
              <td>Shetland / Pônei B</td>
              <td>Pônei</td>
              <td>Pônei D</td>
              <td>Cavalo médio</td>
              <td>Cavalo pequeno</td>
              <td>Cavalo grande</td>
              <td>Cavalo muito grande</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Altura do garrote</th>
              <td>110 - 120 cm</td>
              <td>120 - 130 cm</td>
              <td>130 - 150 cm</td>
              <td>150 - 160 cm</td>
              <td>160 - 170 cm</td>
              <td>170 - 180 cm</td>
              <td>+ 180 cm</td>
            </tr>
            <tr>
              <th>Tamanho para escolher = comprimento das costas</th>
              <td>105 cm</td>
              <td>115 cm</td>
              <td>125 cm</td>
              <td>135 cm</td>
              <td>145 cm</td>
              <td>155 cm</td>
              <td>165 cm</td>
            </tr>
          </tbody>
        </table>
        <h4>SINSERROS</h4>
        <table>
          <thead>
            <tr>
              <th>Montaria : </th>
              <td>Shetland / Pônei B</td>
              <td>Pônei C / D</td>
              <td>Cavalo Pequeno</td>
              <td>Cavalo médio</td>
              <td>Cavalo Grande</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos</th>
              <td>PP</td>
              <td>P</td>
              <td>M</td>
              <td>G</td>
              <td>GG</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default HorsemanshipItems
