const WomenShoes = () => {
  return (
    <>
      <h4>CALÇADOS MULHER</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Comprimento do pé: (em cm)</th>
              <td>20.9 - 21.5</td>
              <td>21.6 - 22.2</td>
              <td>22.3 - 22.8</td>
              <td>22.9 - 23.5</td>
              <td>23.6 - 24.2</td>
              <td>24.3 - 24.9</td>
              <td>25 - 25.6</td>
              <td>25.7 - 26.2</td>
              <td>26.3 - 26.8</td>
              <td>26.9 - 27.5</td>
              <td>27.6 - 28.2</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU</th>
              <td>34</td>
              <td>35</td>
              <td>36</td>
              <td>37</td>
              <td>38</td>
              <td>39</td>
              <td>40</td>
              <td>41</td>
              <td>42</td>
              <td>43</td>
              <td>44</td>
            </tr>
            <tr>
              <th>Tamanhos USA</th>
              <td>3</td>
              <td>4</td>
              <td>4.5</td>
              <td>5.5</td>
              <td>6.5</td>
              <td>7</td>
              <td>8</td>
              <td>8.5</td>
              <td>9.5</td>
              <td>10</td>
              <td>11</td>
            </tr>
            <tr>
              <th>Tamanhos Brasil</th>
              <td>32</td>
              <td>33</td>
              <td>34</td>
              <td>35</td>
              <td>36</td>
              <td>37</td>
              <td>38</td>
              <td>39</td>
              <td>40</td>
              <td>41</td>
              <td>42</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>½ tamanhos disponíveis para alguns produtos:</p>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Comprimento do pé: (em cm)</th>
              <td>21.4 - 21.6</td>
              <td>22.9 - 23.1</td>
              <td>23.4 - 23.6</td>
              <td>24.9 - 25.1</td>
              <td>26.4 - 26.6</td>
              <td>27.4 - 27.6</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU</th>
              <td>34 ½</td>
              <td>36 ½</td>
              <td>37 ½</td>
              <td>39 ½</td>
              <td>41 ½</td>
              <td>43 ½</td>
            </tr>
            <tr>
              <th>Tamanhos USA</th>
              <td>3.5</td>
              <td>5</td>
              <td>6</td>
              <td>7.5</td>
              <td>9</td>
              <td>10.5</td>
            </tr>
            <tr>
              <th>Tamanhos Brasil</th>
              <td>32 ½</td>
              <td>34 ½</td>
              <td>35 ½</td>
              <td>37 ½</td>
              <td>39 ½</td>
              <td>41 ½</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default WomenShoes
