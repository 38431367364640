import { Link } from 'gatsby'

const SizeGuidePrecisionSports = () => {
  return (
    <>
      <section className="tamanho-esporte-precisao guia-section-block">
        <h3>GUIA DE TAMANHOS ESPORTE DE PRECISÃO</h3>
        <h4>ARC</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Sua altura : (em cm)</th>
                <td>110 - 130</td>
                <td>130 - 150</td>
                <td>150 - 170</td>
                <td>170 - 190</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanho universal</th>
                <td>P</td>
                <td>M</td>
                <td>G</td>
                <td>GG</td>
              </tr>
              <tr>
                <th>Tamanho</th>
                <td>58&quot;</td>
                <td>66&quot;</td>
                <td>68&quot;</td>
                <td>70&quot;</td>
              </tr>
              <tr>
                <th>Potência</th>
                <td>15 livres</td>
                <td>18 livres</td>
                <td>20 livres</td>
                <td>22 livres</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuidePrecisionSports
