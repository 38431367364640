const ChildGlove = () => {
  return (
    <>
      <h4>LUVAS CRIANÇA</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da mão: (em cm)</th>
              <td>13.9-14.9</td>
              <td>14.91-15.9</td>
              <td>15.91-16.9</td>
              <td>16.91-17.8</td>
              <td>17.81-18.6</td>
              <td>18.61-19.45</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos universais</th>
              <td>4 anos</td>
              <td>6 anos</td>
              <td>8 anos</td>
              <td>10 anos</td>
              <td>12 anos</td>
              <td>14 anos</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ChildGlove
