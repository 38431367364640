const GirlsUpsideUnderwear = () => {
  return (
    <>
      <h4>
        PARTE DE CIMA / PARTE DE BAIXO MENINA: Camisetas, agasalho com capuz,
        casacos, jaquetas com capuz...
      </h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Altura: (em cm)</th>
              <td>56-63</td>
              <td>64-70</td>
              <td>71-76</td>
              <td>77-82</td>
              <td>83-89</td>
              <td>90-97</td>
              <td>98-104</td>
              <td>105-114</td>
              <td>115-124</td>
              <td>125-132</td>
              <td>133-142</td>
              <td>143-152</td>
              <td>153-162</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos universais</th>
              <td>3 meses</td>
              <td>6 à 9 meses</td>
              <td>9 à 12 meses</td>
              <td>18 meses</td>
              <td>2 anos</td>
              <td>3 anos</td>
              <td>4 anos</td>
              <td>5 anos</td>
              <td>6 anos</td>
              <td>8 anos</td>
              <td>10 anos</td>
              <td>12 anos</td>
              <td>14 anos</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default GirlsUpsideUnderwear
