const HeadAccessoriesMen = () => {
  return (
    <>
      <h4>ACESSÓRIOS DE CABEÇA HOMEM: Bonés, gorros, capacetes...</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida da cabeça : (em cm)</th>
              <td>51.1</td>
              <td>52</td>
              <td>52.9</td>
              <td>53.8</td>
              <td>54.7</td>
              <td>55.6</td>
              <td>56.5</td>
              <td>57.4</td>
              <td>58.3</td>
              <td>59.2</td>
              <td>60.1</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos</th>
              <td>T0</td>
              <td>T1</td>
              <td>T2</td>
              <td>T3</td>
              <td>T4</td>
              <td>T5</td>
              <td>T6</td>
              <td>T7</td>
              <td>T8</td>
              <td>T9</td>
              <td>T10</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default HeadAccessoriesMen
