const Soutiens = () => {
  return (
    <>
      <h4>SOUTIENS</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Medida abaixo do busto: (em cm)</th>
              <td>63-67</td>
              <td>68-72</td>
              <td>73-77</td>
              <td>78-82</td>
              <td>83-87</td>
              <td>88-92</td>
              <td>93-97</td>
              <td>98-102</td>
              <td>103-107</td>
              <td>108-112</td>
              <td>113-117</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos</th>
              <td>80</td>
              <td>85</td>
              <td>90</td>
              <td>95</td>
              <td>100</td>
              <td>105</td>
              <td>110</td>
              <td>115</td>
              <td>120</td>
              <td>125</td>
              <td>130</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>
        Para a parte de cima de maiô de banho mulher amarrado para trás, se seu
        tamanho não está disponível você pode usar outros tamanhos com um volume
        de busto similar.
        <br />
        Exemplo: para um tamanho 95C você pode usar um 100B apertando mais a
        parte de trás, ou um 90D apertando menos a parte de trás.
      </p>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <td colSpan={9}>Medida do peito: (em cm)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Taça AA</th>
              <td>72-74</td>
              <td>77-79</td>
              <td>82-84</td>
              <td>87-89</td>
              <td>92-94</td>
              <td>97-99</td>
              <td>102-104</td>
              <td>107-109</td>
            </tr>
            <tr>
              <th>Taça A</th>
              <td>75-77</td>
              <td>80-82</td>
              <td>85-87</td>
              <td>90-92</td>
              <td>95-97</td>
              <td>100-102</td>
              <td>105-107</td>
              <td>110-112</td>
            </tr>
            <tr>
              <th>Taça B</th>
              <td>78-80</td>
              <td>83-85</td>
              <td>88-90</td>
              <td>93-95</td>
              <td>98-100</td>
              <td>103-105</td>
              <td>108-110</td>
              <td>113-115</td>
            </tr>
            <tr>
              <th>Taça C</th>
              <td>81-83</td>
              <td>86-88</td>
              <td>91-93</td>
              <td>96-98</td>
              <td>101-103</td>
              <td>106-108</td>
              <td>111-113</td>
              <td>116-118</td>
            </tr>
            <tr>
              <th>Taça D</th>
              <td>84-86</td>
              <td>89-91</td>
              <td>94-96</td>
              <td>99-101</td>
              <td>104-106</td>
              <td>109-111</td>
              <td>114-116</td>
              <td>119-121</td>
            </tr>
            <tr>
              <th>Taça E</th>
              <td>87-89</td>
              <td>92-94</td>
              <td>97-99</td>
              <td>102-104</td>
              <td>107-109</td>
              <td>112-114</td>
              <td>117-119</td>
              <td>122-124</td>
            </tr>
            <tr>
              <th>Taça F</th>
              <td>90-92</td>
              <td>95-97</td>
              <td>100-102</td>
              <td>105-107</td>
              <td>111-113</td>
              <td>115-117</td>
              <td>120-122</td>
              <td>125-127</td>
            </tr>
            <tr>
              <th>Taça G</th>
              <td>93-95</td>
              <td>98-100</td>
              <td>103-105</td>
              <td>108-110</td>
              <td>113-115</td>
              <td>118-120</td>
              <td>123-125</td>
              <td>128-130</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Soutiens
