import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'

import WomenShoes from './WomenShoes'
import KidsShoes from './KidsShoes'
import BabyShoes from './BabyShoes'

const SizeGuideShoes = () => {
  return (
    <>
      <section className="tamanho-calcado-homem guia-section-block">
        <h3>GUIA DE TAMANHOS CALÇADOS</h3>
        <div className="guia-section-description">
          <div className="image-container">
            <Image
              src="https://decathlonproqa.vtexassets.com/arquivos/tamanhos-calcado.png"
              alt="shoes-size"
              loading="lazy"
              width={175}
              height={211}
            />
          </div>
          <div className="description-container">
            <span>Para tirar suas medidas:</span>
            <ul>
              <li>
                Comprimento do pé:Coloque-se contra uma parede, descalço,
                calcanhares colados na parede.
                <br />
                Coloque um livro ou um objeto pesado contra seu dedão depois
                meça a distância entre o livro ou o objeto e a parede.
                <br />
                Repita a operação para o outro pé.{' '}
              </li>
              <li>Se você hesita entre dois tamanhos, escolha o maior.</li>
            </ul>
          </div>
        </div>
        <h4>CALÇADOS HOMEM</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Comprimento do pé: (em cm)</th>
                <td>24.3 - 24.9</td>
                <td>25 - 25.6</td>
                <td>25.7 - 26.2</td>
                <td>26.3 - 26.8</td>
                <td>26.9 - 27.5</td>
                <td>27.6 - 28.2</td>
                <td>28.3 - 28.8</td>
                <td>28.9 - 29.5</td>
                <td>29.6 - 30.2</td>
                <td>30.3 - 30.8</td>
                <td>30.9 - 31.5 </td>
                <td>31.6 - 32.2</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos EU</th>
                <td>39</td>
                <td>40</td>
                <td>41</td>
                <td>42</td>
                <td>43</td>
                <td>44</td>
                <td>45</td>
                <td>46</td>
                <td>47</td>
                <td>48</td>
                <td>49</td>
                <td>50</td>
              </tr>
              <tr>
                <th>Tamanhos USA</th>
                <td>6</td>
                <td>7</td>
                <td>7.5</td>
                <td>8.5</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>11.5</td>
                <td>12</td>
                <td>13</td>
                <td>13.5</td>
                <td>14</td>
              </tr>
              <tr>
                <th>Tamanhos Brasil</th>
                <td>37</td>
                <td>38</td>
                <td>39</td>
                <td>40</td>
                <td>41</td>
                <td>42</td>
                <td>43</td>
                <td>44</td>
                <td>45</td>
                <td>46</td>
                <td>47</td>
                <td>49</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>½ tamanhos disponíveis para alguns produtos:</p>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Comprimento do pé: (em cm)</th>
                <td>24.9 - 25.1</td>
                <td>26.4 - 26.6</td>
                <td>27.4 - 27.6</td>
                <td>28.4 - 28.6</td>
                <td>30.4 - 30.6</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos EU</th>
                <td>39 ½</td>
                <td>41 ½</td>
                <td>43 ½</td>
                <td>44 ½</td>
                <td>47 ½</td>
              </tr>
              <tr>
                <th>Tamanhos USA</th>
                <td>6.5</td>
                <td>8</td>
                <td>9.5</td>
                <td>10.5</td>
                <td>12.5</td>
              </tr>
              <tr>
                <th>Tamanhos Brasil</th>
                <td>37 ½</td>
                <td>39 ½</td>
                <td>41 ½</td>
                <td>42 ½</td>
                <td>45 ½</td>
              </tr>
            </tbody>
          </table>
        </div>
        <WomenShoes />
        <KidsShoes />
        <BabyShoes />
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideShoes
