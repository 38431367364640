const BabyShoes = () => {
  return (
    <>
      <h4>CALÇADOS BEBÊ</h4>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Comprimento do pé: (em cm)</th>
              <td>10.9 - 11.5</td>
              <td>11.6 - 12.2</td>
              <td>12.3 - 12.8</td>
              <td>12.9 - 13.5</td>
              <td>13.6 - 14.2</td>
              <td>14.3 - 14.8</td>
              <td>14.9 - 15.5</td>
              <td>15.6 - 16.2</td>
              <td>16.3 - 16.8</td>
              <td>16.9 - 17.5</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU</th>
              <td>19</td>
              <td>20</td>
              <td>21</td>
              <td>22</td>
              <td>23</td>
              <td>24</td>
              <td>26</td>
              <td>26</td>
              <td>27</td>
              <td>28</td>
            </tr>
            <tr>
              <th>Tamanhos USA</th>
              <td>3.5</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
              <td>6.5</td>
              <td>7.5</td>
              <td>8</td>
              <td>9</td>
              <td>9.5</td>
              <td>10.5</td>
            </tr>
            <tr>
              <th>Tamanhos Brasil</th>
              <td>17</td>
              <td>18</td>
              <td>19</td>
              <td>20</td>
              <td>21</td>
              <td>22</td>
              <td>23</td>
              <td>24</td>
              <td>25</td>
              <td>26</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>½ tamanhos disponíveis para alguns produtos:</p>
      <div className="guia-tabela">
        <table>
          <thead>
            <tr>
              <th>Comprimento do pé: (em cm)</th>
              <td>12.4 - 12.6</td>
              <td>13.4 - 13.6</td>
              <td>14.9 - 15.1</td>
              <td>15.9 - 16.1</td>
              <td>17.4 - 17.6</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Tamanhos EU</th>
              <td>20½</td>
              <td>21½</td>
              <td>23½</td>
              <td>25½</td>
              <td>27½</td>
            </tr>
            <tr>
              <th>Tamanhos USA</th>
              <td>4.5</td>
              <td>5.5</td>
              <td>7</td>
              <td>8.5</td>
              <td>10</td>
            </tr>
            <tr>
              <th>Tamanhos Brasil</th>
              <td>18½</td>
              <td>19½</td>
              <td>21½</td>
              <td>23½</td>
              <td>25½</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default BabyShoes
