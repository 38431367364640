import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'

import WomenUnderwear from './WomenUnderwear'
import WomenSoutiens from './WomenSoutiens'

const SizeGuideWomen = () => {
  return (
    <>
      <section className="tamanho-vestuario-mulher guia-section-block">
        <h3>GUIA DE TAMANHOS TÊXTEIS MULHER</h3>
        <div className="guia-section-description">
          <div className="image-container">
            <Image
              src="https://decathlonproqa.vtexassets.com/arquivos/tamanhos-mulher.png"
              alt="woman-size"
              loading="lazy"
              width={220}
              height={404}
            />
          </div>
          <div className="description-container">
            <span>Para tirar suas medidas:</span>
            <ul>
              <li>
                Medida da cabeça: Coloque uma fita métrica acima das
                sobrancelhas. Certifique-se de que está paralela ao chão quando
                sua cabeça está à direita
              </li>
              <li>
                Medida do peito: Coloque uma fita métrica no lugar mais próximo
                do peito. Certifique-se de que está paralela ao chão.
                <br />
                Levante a cabeça, aperte a fita métrica respirando normalmente.
              </li>
              <li>
                Medida abaixo do peito: Coloque uma fita métrica logo abaixo dos
                seios. Certifique-se de que está paralela ao chão. Levante a
                cabeça, aperte a fita métrica respirando normalmente.{' '}
              </li>
              <li>
                Medida da cintura: Coloque uma fita métrica abaixo do umbigo no
                lugar mais fino de sua cintura.
                <br />
                Levante a cabeça, aperte a fita métrica respirando normalmente.
              </li>
              <li>Medida da bacia: Meça no lugar mais forte.</li>
              <li>
                Medida da mão: Coloque uma fita métrica na dobra do polegar.
                Meça a palma com a fita métrica.
              </li>
              <li>
                Comprimento do pé: Coloque-se contra uma parede, descalço,
                calcanhares colados na parede.
                <br />
                Coloque um livro ou um objeto pesado contra seu dedão. Meça a
                distância entre o livro ou o objeto e a parede.
              </li>
            </ul>
          </div>
        </div>
        <h4>
          PARTE DE CIMA MULHER: Camisetas, regatas, camisas, jaquetas com capuz,
          agasalho com capuz, casacos, roupa de banho...
        </h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida do peito: (em cm)</th>
                <td>80-83</td>
                <td>84-87</td>
                <td>88-91</td>
                <td>92-95</td>
                <td>96-103</td>
                <td>104-113</td>
                <td>114-123</td>
                <td>124-135</td>
                <td>136-146</td>
                <td>147-159</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos EU - Europa</th>
                <td>2XS</td>
                <td>XS</td>
                <td>S</td>
                <td>M</td>
                <td>L</td>
                <td>XL</td>
                <td>2XL</td>
                <td>3XL</td>
                <td>4XL</td>
                <td>5XL</td>
              </tr>
              <tr>
                <th>Tamanhos</th>
                <td>3P</td>
                <td>PP</td>
                <td>P</td>
                <td>M</td>
                <td>G</td>
                <td>GG</td>
                <td>3G</td>
                <td>4G</td>
                <td>5G</td>
                <td>6G</td>
              </tr>
            </tbody>
          </table>
        </div>
        <WomenSoutiens />
        <WomenUnderwear />
        <h4>ACESSÓRIOS DE CABEÇA HOMEM: Bonés, gorros, capacetes...</h4>
        <div className="guia-tabela">
          <table>
            <thead>
              <tr>
                <th>Medida da cabeça : (em cm)</th>
                <td>51.1</td>
                <td>52</td>
                <td>52.9</td>
                <td>53.8</td>
                <td>54.7</td>
                <td>55.6</td>
                <td>56.5</td>
                <td>57.4</td>
                <td>58.3</td>
                <td>59.2</td>
                <td>60.1</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Tamanhos</th>
                <td>T0</td>
                <td>T1</td>
                <td>T2</td>
                <td>T3</td>
                <td>T4</td>
                <td>T5</td>
                <td>T6</td>
                <td>T7</td>
                <td>T8</td>
                <td>T9</td>
                <td>T10</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link to="#main" className="link-topo">
          Topo
        </Link>
      </section>
    </>
  )
}

export default SizeGuideWomen
